<script setup>
import { onMounted, ref } from 'vue'

const props = defineProps({
  variant: {
    type: String,
    default: 'info',
    validator(value) {
      return ['success', 'danger', 'warning', 'info'].includes(value)
    },
  },
  dismissible: {
    type: Boolean,
    default: false,
  },
  duration: {
    type: Number,
    default: 0,
  },
})

const emit = defineEmits(['dismissed'])
const show = ref(true)

function emitDismissed() {
  emit('dismissed')
}

function close() {
  show.value = false
  emitDismissed()
}

function setTimer() {
  setTimeout(() => {
    if (show.value) close()
  }, props.duration)
}

onMounted(() => {
  if (props.duration) {
    setTimer()
  }
})
</script>

<template>
  <Transition name="fade" @after-leave="emitDismissed">
    <div
      v-if="show"
      id="alert"
      role="alert"
      :class="[`alert alert-${variant}`, { 'alert-dismissible': dismissible }]"
      :data-nw="'alert-' + variant"
    >
      <div class="container d-flex align-content-stretch">
        <button
          v-if="dismissible"
          type="button"
          class="btn-close"
          aria-label="Close"
          @click="close()"
        ></button>

        <slot name="icon" />

        <div class="text-break">
          <slot />
        </div>
      </div>
    </div>
  </Transition>
</template>

<style lang="scss" scoped>
.fade {
  &-enter-from,
  &-leave-to {
    opacity: 0;
  }

  &-enter-to,
  &-leave-from {
    opacity: 1;
  }

  &-enter-active,
  &-leave-active {
    transition: opacity 0.2s linear;
  }
}

.alert :slotted(svg) {
  height: 24px;
  margin-right: 16px;
  flex-shrink: 0;
}

.btn-close {
  background-size: 0.8rem;
}
</style>
